import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import SubHero from '../../components/elements/sub-hero/sub-hero';
import TwoColFullScreen from '../../components/layouts/2-col-full-screen/2-col-full-screen';
import ServiceInfo from '../../components/services/service-info/service-info';
import BookCta from '../../components/elements/book-cta/book-cta';
import Testimonials from '../../components/elements/testimonials/testimonials';
import InstaFeed from '../../components/elements/insta-feed/insta-feed';

const WeddingHairPage = ({ data }) => {

  let [loaded, setLoaded] = useState(true)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Layout pageType='hair'>
      <SEO
        keywords={[`Wedding Hair Ely`, `Wedding Makeup Ely`,]}
        title="Wedding Hair & Make-Up 2022 - Going fast"
        description="We're a luxury hair salon & hairdressers based in the heart of Ely, our salon offers a range of wedding Hair & Make-Up packages. We also are able to offer keratin-smoothing treatments, to eliminate frizz and create smooth, shiny hair."
      />
      <SubHero 
        loaded={loaded}
        bgImage={data.herofull.childImageSharp.fluid}
        data={{
          title: 'Wedding Hair & Make-Up 2022',
          subTitle: "We're a luxury hair salon & hairdressers based in the heart of Ely, our salon offers a range of wedding Hair & Make-Up packages.",
        }}
      />
      {loaded && <div>
      <TwoColFullScreen cols={[{
          bg: 'text-white bg-primary-darker',
          content: <div>
            <ServiceInfo 
              key={1}
              title="HAIR"
              smallPrice
              alignPrice="bottom"
              upperText={<div>
                  <p>
                  Mileage – 40p per mile charged after reaching 15 miles from the Salon.
                  <br/> <br/>
                  A non refundable deposit will become payable on the day of booking to secure your wedding date.
                  </p>
                </div>
              }
            />
            <ServiceInfo 
              key={2}
              smallPrice
              priceList={[
                {
                  price: '£185.00',
                  text: `This includes your hair on the wedding day and a trial to ensure that we have the
                  best possible style for you. It is important that we have the time to get to know each
                  other and to find out what works best for your big day to ensure that everything runs
                  smoothly.
                  The morning of the wedding should be fun and relaxed, so it is important we are all
                  set.`,
                  padding: 'pr-6'
                }
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-2xl -mb-6 font-bold">Total costing for Bride</h3>
                </div>
              }
            />
            <ServiceInfo 
              key={2}
              smallPrice
              priceList={[
                {
                  price: '£75 per person on the wedding day.',
                  text: `This will include Bridesmaids, Mother of the Bride or anyone that might want their
                  hair done at the location.`,
                  padding: 'pr-6'
                }
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-2xl -mb-6 font-bold">Bridal Party Hair</h3>
                </div>
              }
            />
            <ServiceInfo 
              key={3}
              smallPrice
              priceList={[
                {
                  price: '£45 per person',
                  padding: 'pr-6'
                }
              ]}
              alignPrice="bottom"
              upperText={<div>
                  <h3 className="text-2xl -mb-6 font-bold">Bridesmaids or children under 10</h3>
                </div>
              }
            />
          </div>
        },
        {
          bg: 'bg-white',
          content: <div>
          <ServiceInfo 
            key={1}
            title="MAKE-UP"
            smallPrice
            alignPrice="bottom"
            upperText={<div>
                <p>
                Mileage – 40p per mile charged after reaching 15 miles from the Salon.
                <br/> <br/>
                A non refundable deposit will become payable on the day of booking to secure your wedding date.
                </p>
              </div>
            }
          />
          <ServiceInfo 
            key={2}
            smallPrice
            priceList={[
              {
                price: '£175.00',
                text: `This includes your make-up on the wedding day and a trial, again to ensure we have
                everything exactly as you want it.`,
                padding: 'pr-6'
              }
            ]}
            alignPrice="bottom"
            upperText={<div>
                <h3 className="text-2xl -mb-6 font-bold">Total costing for Bride</h3>
              </div>
            }
          />
          <ServiceInfo 
            key={2}
            smallPrice
            priceList={[
              {
                price: '£65 per person (as above)',
                padding: 'pr-6'
              }
            ]}
            alignPrice="bottom"
            upperText={<div>
                <h3 className="text-2xl -mb-6 font-bold">Bridal Party Make-up</h3>
              </div>
            }
          />
        </div>
        },
      ]}/>
      <Testimonials />
      <BookCta discount="https://gift-cards.phorest.com/salons/houseofpureltd" link="https://phorest.com/book/salons/houseofpureltd" phone="01353 661 321" email="info@houseofpure.co.uk" bg={data.bookbg.childImageSharp.fluid} marginBottom />
      <InstaFeed token="IGQVJWSFBsWDctOXpzeVo4S1VNYjJOc0hHbnY4OHR4ZAUZAPc193c3pJRGY1M2NNVS16RnpRaHpJeEF3Yy1HRjN4OVQ2QndlZAzdhWFlsYkhoYTVOcmZA4cTYyMzFNOVBtdnBmX0VEbE5yVHFabkNka1VDdAZDZD" />
      </div>}
    </Layout>
  );
}

WeddingHairPage.propTypes = {
  data: PropTypes.object,
};

const indexQuery = graphql`
  query {
    herofull: file(relativePath: { eq: "wedding.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bookbg: file(relativePath: { eq: "booking-bg-blurry.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const WeddingHairComponent = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <WeddingHairPage props data={data} {...props} />
    )}
  />
)

WeddingHairComponent.displayName = "WeddingHairComponent"

export default WeddingHairComponent